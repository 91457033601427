
.container-fluid {
	padding: 20px;

	.reminder {
		display: grid;
		grid-column-gap: 20px;
		grid-template-columns: 450px 1fr;
	}
}
@media only screen and (max-width: 850px) {
	.container-fluid {
		.reminder {
			grid-template-columns: 300px 1fr;
		}
	}
}
@media only screen and (max-width: 730px) {
	.container-fluid {
		.trigger {
			display: none;
		}
	}
}
